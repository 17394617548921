@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Thin.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-ThinItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Medium.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-MediumItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}



@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('/fonts/AlegreyaSansSC/AlegreyaSansSC-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}