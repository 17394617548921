@import "attributes";

body {
  margin: 0;
  padding: 0;

  font-size: 1.1rem;
  background-color: #fefefb;
  font-family: $font;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.ios #page {
  min-height: 86vh;
  max-height: 86vh;
  height: 86vh;

  max-width: 100vw;
}

#page {
  min-height: 100vh;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $lean-width) {
    align-items: initial;
  }

  main {
    max-width: 800px;

    article {
      max-width: 800px;
    }

    @media screen and (min-width: $lean-width-plus) {
      &.wide, &.wide article {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    @media screen and (max-width: 920px) {
      padding: 0 1em 0;
    }

    .double-container {
      @media screen and (max-width: $lean-width-plus) {
        display: block;
      }

      display: flex;
      flex-direction: row;

      div {
        flex: auto;
      }
    }
  }

  header {
    width: 940px;

    @media screen and (max-width: $stick-header-width) {
      width: 100vw;
    }

    #header-contents {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;

      > a {
        text-decoration: none;
      }

      @media screen and (max-width: $stick-header-width) {
        padding: 0 1em 0;
      }

      @media screen and (max-width: $lean-width - 200) {
        display: block;
      }

      nav {
        font-size: 1.6em;
        font-weight: 700;

        ul {
          list-style: none;
          padding-left: 0;
          margin-left: 0;
          font-size: 1.3rem;

          li {
            display: inline;

            * {
              padding: 0 0.15em;
            }
          }
        }

        @media screen and (max-width: $lean-width) {
          margin-left: -0.13em;
        }
      }
    }
  }

  footer {
    padding: 1em;
    font-size: 1rem;
    text-align: center;

    @media screen and (max-width: $lean-width) {
      text-align: initial;
    }
  }
}