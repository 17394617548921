@import "attributes";
@import "mixins";

p {
  line-height: 1.5rem;

  + ol {
    margin-top: 0;
  }
}

a {
  color: $black;
  text-decoration-color: $accent;

  &:hover, &:hover * {
    color: $black-down;
  }

  &:active, &:active * {
    color: $accent;
  }

  &.fragment { // style for href="#<fragment>" links
    text-decoration-style: dashed;
    text-decoration-width: 1.5px;
  }

  &.scale {
    text-decoration: none;
    color: initial;

    @include hover-scale(1.05);

    &:hover, &:hover *, &:active, &:active * {
      color: initial;
    }
  }
}

h1, h2, h3 {
  font-family: $heading-font;
}

h1 {
  font-weight: 800;
  letter-spacing: 3px;
}

h2, h3 {
  font-weight: 700;
}

hr {
  margin: 2em 0;
  border: 1px solid rgb(234, 234, 234);
}

img {
  max-width: 100%;
}


details {
  background-color: $accent-light;
  padding: 1em;
  outline: 0 !important;

  summary {
    cursor: pointer;
  }

  &[open] {
    summary {
      margin-bottom: 1em;
    }

    summary ~ div {
      margin-top: -1rem;
    }
  }

  &:first-of-type {
    border-radius: $round-corner $round-corner 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 $round-corner $round-corner;
  }
}

mark {
  background-color: $accent-light;
}

ol.multiline, ul.multiline {
  li {
    margin-bottom: 0.3rem;
  }
}

ul.todo {

  list-style: none;
  line-height: 2rem;

  li {
    &:before {
      content: "○";
      display: inline-block;
      width: 1.5em;
      margin-left: -1.5em
    }
  }

  li.done {
    &:before {
      content: "✓";
    }
  }
}

h2 {
  margin-block-start: 2rem;
  margin-block-end: 0;

  + p {
    margin-block-start: 0.5rem;
  }
}

p {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}