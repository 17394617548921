@mixin hover-scale($scale) {

  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: scale($scale);

    &.point3 {
      transform: scale(1.03);
    }

    &.point2 {
      transform: scale(1.02);
    }

    &.point0 {
      transform: none;
      transition: none;
    }
  }

  &:hover:active {
    transform: scale(1/$scale);
  }
}
