.none {
  display: none !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

a.implicit {
  text-decoration: none;
}