/* 💝 */ $accent: rgb(250, 160, 0); $accent-light: rgba(250, 160, 0, 0.2);
$black: #000000;
$black-down: #3f3f3f;

$lean-width: 760px;
$lean-width-plus: 832px;
$stick-header-width: 980px;

$round-corner: 0.7em;


$font: "Open Sans", sans-serif;
$heading-font: "Alegreya Sans SC", serif;