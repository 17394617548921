@import "attributes";
@import "layout";
@import "elements";
@import "modifiers";
@import "mixins";
@import "../fonts/OpenSans/opensans";
@import "../fonts/AlegreyaSansSC/alegreya-sans-sc";

#page {
  .regular-block {

    @media screen and (min-width: $lean-width-plus) {
      width: 800px;
    }

    @media screen and (max-width: $lean-width-plus) {
      width: 92vw;
    }

  }


  .apps {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
    margin-top: 1em;

    .app {
      flex-basis: 50%;

      > div {
        display: flex;
        flex-flow: row wrap;
        align-content: start;
        justify-content: start;

        .icon {
          width: 96px;

          img {
            width: 96px;
          }

          margin-top: 1.2rem;
          margin-left: 1em;
          margin-right: 1em;
        }


        @media screen and (max-width: 600px) {
          .icon {
            width: 80px;
            margin-top: 1.6rem;
            margin-left: 0;

            img {
              width: 80px;
            }
          }

          .description {
            .get-app-store {
              width: 8em !important;
            }
          }
        }

        .description {
          width: 350px;

          h2 {
            margin-top: 1.2rem;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          p {
            &.subtitle {
              font-style: italic;
              margin-top: 0.5rem;
              margin-bottom: 0.5rem;
            }

            &.legal {
              font-size: 0.9rem;
              margin-top: 0;
            }
          }

          .get-app-store {
            width: 10em;
            margin-top: 0.05rem;
          }
        }
      }
    }

    @media screen and (max-width: 980px) {
      .app {
        flex-basis: 100%;
        width: 100%;

        > div {
          justify-content: center;
        }
      }
    }

    @media screen and (max-width: 500px) {
      .app {
        > div {
          .icon {

            margin-right: 1em;
            width: 60px;

            img {
              width: 60px;
            }
          }

          justify-content: start;

          .description {
            width: 260px;

            h2 {
              margin-top: 1.3rem;
              margin-bottom: 0;
            }

            p {
              &.subtitle {
                font-style: normal;
                font-size: 0.9rem;
                margin-top: 0.3rem;
                margin-bottom: 0.3rem;
                line-height: 1rem;
              }

              &.legal {
                font-size: 0.8rem;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .apps-download {
    margin: 2em 0 2.5em;
    display: flex;
    flex-flow: row wrap;
    align-content: space-evenly;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1500px) {
      max-width: 800px;
      justify-content: start;
    }

    .app {
      flex-basis: 372px;

      > div {
        display: flex;
        flex-flow: row wrap;
        align-content: start;
        justify-content: start;

        .icon {
          width: 96px;

          img {
            width: 96px;
          }

          margin-top: 1.2rem;
          margin-left: 1em;
          margin-right: 1em;
        }


        @media screen and (max-width: 600px) {
          .icon {
            width: 80px;
            margin-top: 1.6rem;
            margin-left: 0;

            img {
              width: 80px;
            }
          }

          .description {
            .get-app-store {
              width: 8em !important;
            }
          }
        }

        .description {
          width: 240px;

          h2 {
            margin-bottom: 0.5rem;
            margin-top: 1.2rem;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          p {
            &.subtitle {
              margin-top: 0.5rem;
              margin-bottom: 0.5rem;
            }

            &.legal {
              font-size: 0.9rem;
              margin-top: 0;
            }
          }

          .get-app-store {
            width: 10em;
            margin-top: 0.05rem;
          }
        }
      }
    }

    @media screen and (max-width: $lean-width) {
      .app {
        flex-basis: 100%;
        width: 100%;
      }
    }

    @media screen and (max-width: 500px) {
      .app {
        > div {
          justify-content: start;

          .description {
            width: 180px;
          }
        }
      }
    }
  }

  input#email {
    width: 184px;
    padding: 0.1em 0.3em;
  }

  .highlights {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: space-between;
    width: 100%;
    max-width: 1600px;
    margin-top: 1em;

    .highlight {

      section {
        .illustration {
          display: none;
        }

        .content-and-icon {

          .preview-icon {
            opacity: 1;
            transition: opacity 0.2s ease-out, transform 0.2s linear;
          }

          .content {
            min-width: 0;

            p {
              max-height: 1.5rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media (hover: none) {
                //&:before {
                //  content: "MORE";
                //  font-size: 1rem;
                //  padding-left: 0.5em;
                //  font-weight: bold;
                //  text-decoration: underline;
                //  text-decoration-color: $accent;
                //  float: right;
                //  vertical-align: baseline;
                //}
              }
            }
          }

          @media screen and (max-width: 1100px) and (min-width: 900px) {
            flex-flow: column;

            .preview-icon {
              max-width: 64px !important;
              max-height: 64px !important;
              min-width: 64px !important;
              min-height: 64px !important;
              width: 64px !important;
              height: 64px !important;
              align-self: center;
              margin-left: 0;
              margin-right: 0;
            }

            .content {
              align-self: start;
              min-width: 0;
              max-width: 100%;

              p {
                max-width: 100%;
              }
            }
          }

          .details {
            display: none;
          }
        }
      }

      &.placeholder {
        transform: scale(1.05);

        .preview-icon {
          opacity: 0;
          transform: translateX(30%);
        }

        section {
          visibility: hidden;
        }
      }
    }
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.0);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.modal {
  position: absolute;
  cursor: auto;
  transform: scale(1.05);
  transition: 0.3s ease-out, padding 0.3s ease-out;
  overflow-y: hidden;

  .content-and-icon {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @media screen and (max-width: 1100px) and (min-width: 900px) {
      flex-flow: column;

      .preview-icon {
        max-width: 64px !important;
        max-height: 64px !important;
        align-self: center;
        margin-left: 0;
        margin-right: 0;
      }

      .content {
        align-self: start;
      }
    }
  }

  .illustration {
    display: block;
    margin: 0 auto;
    transform: translateY(-100%);
    max-height: 0;
    opacity: 0;
    transition: transform 0.6s, opacity 0.6s, max-height 0.6s;
  }


  .content-and-icon {

    .preview-icon {
      opacity: 0;
      transition: max-width 0.2s linear, max-height 0.2s linear, width 0.2s linear, opacity 0.3s linear, margin-left 0.2s, margin-right 0.2s;
    }

    .content {
      h3 {
        font-size: 1.2rem;
        margin-block-end: 0;
      }

      p {
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
      }

      .details {
        display: block;
        transform: translateY(-100%);
        max-height: 0;
        opacity: 0;
        transition: transform 0.6s, opacity 0.6s, max-height 0.6s;
      }
    }
  }

  &.opening {
    .content-and-icon {
      .preview-icon {
        max-width: 0;
        min-width: 0;
        width: 0;
        @media screen and (max-width: 1100px) and (min-width: 900px) {
          max-width: 64px !important;
          max-height: 0 !important;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  &.open {
    transform: scale(1.1);
    overflow-y: auto;

    .illustration {
      transform: translateY(0);
      max-height: 300px;
      opacity: 1;
    }

    .content-and-icon {

      .preview-icon {
        @media screen and (max-width: 1100px) and (min-width: 900px) {
          max-width: 64px !important;
          max-height: 0 !important;
        }
        max-width: 0;
        min-width: 0;
        width: 0;
        margin-left: 0;
        margin-right: 0;
      }

      .content {

        .details {
          transform: translateY(0);
          max-height: 40rem;
          opacity: 1;
        }
      }
    }
  }

  &.close {
    .illustration {
      transform: translateY(-100%);
      max-height: 0;
      opacity: 0;
      transition: transform 0.150s ease-in, opacity 0.150s ease-in, max-height 0.150s ease-in;
    }

    .content {
      p {
        max-height: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.1s;
      }

      .details {
        opacity: 0;
        transition: opacity 0.1s ease-in;
      }
    }

    transition: 0.2s ease-in-out, padding 0.2s;
  }

  .close-container {
    position: relative;
    width: 0;
    height: 0;
    opacity: 0;
    transition: 0.3s;
    float: right;
    z-index: 10;

    button {
      position: absolute;
      font-family: Arial, sans-serif;
      background-color: rgb(225, 225, 220);
      height: 30px;
      width: 30px;
      line-height: 26px;
      border: 0;
      border-radius: 15px;
      cursor: pointer;
      top: 1.3em;
      left: -1.5em;
      text-align: center;
      font-size: 0.8em;
      padding: 0;

      &:before {
        content: "✕";
      }

      &:hover {
        @include hover-scale(1.1);
      }

      &:active {
        color: black;
        background-color: rgb(200, 200, 195);
      }
    }
  }
}


.highlight {
  max-width: 380px;
  width: 23%;
  background: rgb(248, 248, 245);
  padding: 1em;
  margin: 1em;
  border-radius: $round-corner;


  @media screen and (max-width: 900px) {
    width: 40%;
  }

  @media screen and (max-width: $lean-width) {
    max-width: 100%;
    width: 100%;
    margin: 1em 0;
  }

  section .content-and-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1100px) and (min-width: 900px) {
      flex-flow: column;
    }

    .preview-icon {
      max-width: 15%;
      width: 15%;
      min-width: 15%;
      margin-right: 1em;
    }

    .content {
      min-width: 0;
      max-width: 75vw;

      h2 {
        margin-block-start: 0.5rem;
        margin-block-end: 0;
        font-size: 1.4rem;
      }

      p {
        margin-block-start: 0.5rem;
        margin-block-end: 0.5rem;
      }
    }
  }
}

.focused {
  background-color: rgba(250, 160, 0, 0.2);
  padding: 1em 1em 1em 1em;
  outline: 0 !important;
  border-radius: 0.7em 0.7em 0.7em 0.7em;
  transition: background-color 1s, padding 1s;

  h2 {
    margin-top: 0;
  }
}
